/* eslint-disable camelcase */
import Vue from 'vue';
import Router from 'vue-router';
import Index from './views/Index.vue';
import Terms from './views/Terms.vue';

Vue.config.productionTip = false;

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    /* Book A Seat */
    {
      path: '/',
      name: 'Index',
      component: Index,
    },
    {
      path: '/TermsOfUse',
      name: 'Terms',
      component: Terms,
    },
  ],
});